import "./editions.css";
import Products from "../components/Products";
import { useState, useEffect } from "react";
import { db } from "../firebaseconfig";

const Editions = () => {
  const [books, setBooks] = useState([]);

  useEffect(() => {
    getBooks();
  }, []);

  const getBooks = () => {
    db.collection("books")
      .orderBy("timestamp", "desc")
      .onSnapshot(function (querySnapshot) {
        setBooks(
          querySnapshot.docs.map((book) => ({
            id: book.id,
            ...book.data(),
          }))
        );
      });
  };

  return (
    <div className="editions">
      <Products
        type="ideology"
        books={books.filter((book) => book.subject === "ideology").slice(0, 5)}
      />
      <Products
        type="quraan"
        books={books.filter((book) => book.subject === "quraan").slice(0, 5)}
      />
      <Products
        type="fikh"
        books={books.filter((book) => book.subject === "fikh").slice(0, 5)}
      />
      <Products
        type="akida"
        books={books.filter((book) => book.subject === "akida").slice(0, 5)}
      />
      <Products
        type="sira"
        books={books.filter((book) => book.subject === "sira").slice(0, 5)}
      />
      <Products
        type="hadith"
        books={books.filter((book) => book.subject === "hadith").slice(0, 5)}
      />
      <Products
        type="history"
        books={books.filter((book) => book.subject === "history").slice(0, 5)}
      />
      <Products
        type="tarbya"
        books={books.filter((book) => book.subject === "tarbya").slice(0, 5)}
      />
      <Products
        type="sociology"
        books={books.filter((book) => book.subject === "sociology").slice(0, 5)}
      />
      <Products
        type="philosophy"
        books={books
          .filter((book) => book.subject === "philosophy")
          .slice(0, 5)}
      />
      <Products
        type="law"
        books={books.filter((book) => book.subject === "law").slice(0, 5)}
      />
      <Products
        type="politics"
        books={books.filter((book) => book.subject === "politics").slice(0, 5)}
      />
      <Products
        type="nahw"
        books={books.filter((book) => book.subject === "nahw").slice(0, 5)}
      />
      <Products
        type="balagha"
        books={books.filter((book) => book.subject === "balagha").slice(0, 5)}
      />
      <Products
        type="lisanyat"
        books={books.filter((book) => book.subject === "lisanyat").slice(0, 5)}
      />
      <Products
        type="stories"
        books={books.filter((book) => book.subject === "stories").slice(0, 5)}
      />
      <Products
        type="poetry"
        books={books.filter((book) => book.subject === "poetry").slice(0, 5)}
      />
      <Products
        type=""
        books={books.filter((book) => book.subject === "").slice(0, 5)}
      />
    </div>
  );
};

export default Editions;

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";

import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.React_App_API_KEY,
  authDomain: process.env.React_App_AUTH_DOMAIN,
  projectId: process.env.React_App_PROJECT_ID,
  storageBucket: process.env.React_App_STORAGE_BUCKET,
  messagingSenderId: process.env.React_App_MSG_SENDER_ID,
  appId: process.env.React_App_APP_ID,
  measurementId: process.env.React_App_MEASURE_ID,
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
getAnalytics(app);

const db = firebase.firestore();
const storage = firebase.storage();
const auth = firebase.auth();

export { db, storage, auth };

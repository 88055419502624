import React, { useContext, useState, useEffect } from 'react'
import { auth } from '../firebaseconfig';

const AuthContext = React.createContext()

export function useAuth(){
    return useContext(AuthContext);
}

export function AuthProvider({ children }){

    const [currentUser, setCurrentUser] = useState(null)
    const [loading, setLoading] = useState(true)

    const login = (email, password) => {
        setLoading(true)
        return auth.signInWithEmailAndPassword(email, password)
            .catch(error => {console.log(error);})
            .finally(() => {setLoading(false)})
    }
    
    const logout = () => {
        return auth.signOut();
    }

    useEffect(() => {
        setLoading(true)
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
        })
        return unsubscribe
    }, [currentUser])

    const value = {
        currentUser,
        loading,
        login,
        logout
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}
import "./services.css";
import Service from "./Service";
import { BsTruck } from "react-icons/bs";
import { MdMenuBook, MdDeliveryDining } from "react-icons/md";

const Services = () => {
  return (
    <div className="services">
      <Service
        name="النشر"
        desc="عليك التأليف وعلينا النشر"
        link="https://wa.me/213540988613"
        icon={<MdMenuBook size={40} />}
      />
      <Service
        name="التوزيع"
        desc="طبعت كتابك نحن هنا لتوزيعه"
        link="https://wa.me/213560941010"
        icon={<BsTruck size={40} />}
      />
      <Service
        name="التوصيل"
        desc="اطلب كتابك ونحن نتكفل بتوصيله لك حيث كنت"
        link="https://wa.me/213561621111"
        icon={<MdDeliveryDining size={40} />}
      />
    </div>
  );
};

export default Services;

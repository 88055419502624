import "./footer.css";
import { Link } from "react-router-dom";
import { BsFacebook, BsInstagram } from "react-icons/bs";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-right">
        <img src="./static/logo.png" alt="logo" />
      </div>
      <div className="footer-left">
        <ul className="footer-list">
          <li>
            <Link className="link" to="/about-us">
              من نحن
            </Link>
          </li>
          <li>
            <Link className="link" to="/contact-us">
              إتصل بنا
            </Link>
          </li>
          <li>
            <Link className="link" to="/conditions">
              التعامل معنا
            </Link>
          </li>
          <li>
            <a
              className="link"
              href="https://www.facebook.com/elwaailibrairebmr/"
              target="_blank"
              rel="noreferrer noopener"
            >
              مكتبة الوعي
            </a>
          </li>
          {/* <li>
            <Link className="link" to="/">
              أين تجدنا
            </Link>
          </li> */}
        </ul>
      </div>
      <div className="footer-mid">
        <ul className="footer-list">
          <li>
            <a
              className="link fb"
              href="https://www.facebook.com/darelwaai/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <BsFacebook size={30} />
            </a>
          </li>
          <li>
            <a
              className="link ig"
              href="https://www.instagram.com/dar_elwaai"
              target="_blank"
              rel="noreferrer noopener"
            >
              <BsInstagram size={30} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;

import "./editbook.css";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { db } from "../firebaseconfig";

const EditBook = () => {
  const { id } = useParams();

  const [book, setBook] = useState({
    title: "",
    author: "",
    bookSummary: "",
    price: "",
    subject: "",
  });

  useEffect(() => {
    const getBook = () => {
      db.collection("books")
        .doc(id)
        .get()
        .then((snapshot) => setBook(snapshot.data()));
    };
    getBook();
  }, [id]);
  console.log(book);

  const submitChange = (e) => {
    e.preventDefault();
    db.collection("books")
      .doc(id)
      .update({
        ...book,
      });
  };

  return (
    <div className="editbook">
      <div className="editbook-container">
        <h1>تعديل الكتاب</h1>
        <form action="submitt" onSubmit={submitChange} className="addbook-form">
          <input
            type="text"
            className="addbook-inp"
            value={book.title}
            onChange={(e) => {
              setBook({ ...book, title: e.target.value });
            }}
            placeholder="عنوان الكتاب"
          />
          <input
            type="text"
            className="addbook-inp"
            value={book.author}
            onChange={(e) => {
              setBook({ ...book, author: e.target.value });
            }}
            placeholder="المؤلف"
          />
          <textarea
            rows="6"
            type="text"
            className="addbook-inp"
            value={book.bookSummary}
            onChange={(e) => {
              setBook({ ...book, bookSummary: e.target.value });
            }}
            placeholder="ملخص الكتاب"
          />
          <input
            type="text"
            className="addbook-inp"
            value={book.price}
            onChange={(e) => {
              setBook({ ...book, price: e.target.value });
            }}
            placeholder="السعر"
          />
          <select
            name="pets"
            className="addbook-inp"
            id="pet-select"
            onChange={(e) => {
              setBook({ ...book, subject: e.target.value });
            }}
            value={book.subject}
          >
            <option value="">--موضوع الكتاب(أخرى)--</option>
            <option value="soon">قريبا</option>
            <option value="ideology">كتب فكرية</option>
            <option value="quraan">كتب علوم القرآن والتفسير</option>
            <option value="fikh">كتب الفقه وأصوله</option>
            <option value="akida">كتب العقيدة</option>
            <option value="sira">كتب السيرة</option>
            <option value="hadith">كتب علوم الحديث</option>
            <option value="history">كتب تاريخية</option>
            <option value="tarbya">كتب التربية</option>
            <option value="sociology">كتب الاجتماع</option>
            <option value="philosophy">كتب الفلسفة</option>
            <option value="law">كتب القانون</option>
            <option value="politics">كتب السياسة</option>
            <option value="nahw">كتب النحو والصرف</option>
            <option value="balagha">كتب البلاغة</option>
            <option value="lisanyat">كتب اللسانيات</option>
            <option value="literature">كتب الأدب</option>
            <option value="stories">الرواية والقصة</option>
            <option value="poetry">كتب الشعر</option>
          </select>
          <input type="file" className="addbook-inp" />
          <button type="submit" className="addbook-btn">
            تعديل
          </button>
        </form>
        <button className="addbook-btn-control">
          <Link className="link" to="/control">
            لوحة التحكم
          </Link>
        </button>
      </div>
    </div>
  );
};

export default EditBook;

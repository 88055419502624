import "./main.css";
import Hero from "../components/Hero";
import Services from "../components/Services";
import Show from "../components/Show";
import { useEffect, useState } from "react";
import { db } from "../firebaseconfig";
import Search from "../components/Search";
import Offers from "../components/Offers";

const Main = () => {
  const [books, setBooks] = useState([]);

  useEffect(() => {
    getBooks();
  }, []);

  const getBooks = () => {
    db.collection("books")
      .orderBy("timestamp", "desc")
      .onSnapshot(function (querySnapshot) {
        setBooks(
          querySnapshot.docs.map((book) => ({
            id: book.id,
            ...book.data(),
          }))
        );
      });
  };

  return (
    <div className="main">
      <Hero books={books.slice(0, 7)} />
      <Services />
      {/* <Offers /> */}
      <Search books={books.slice(0, 7)} />
      <Show books={books} />
    </div>
  );
};

export default Main;

import "./contact.css";
import { BsFacebook, BsWhatsapp } from "react-icons/bs";

const Contact = () => {
  return (
    <div className="contact">
      <img src="./static/logo.png" alt="logo" />
      <div className="contact-container">
        <div className="contact-field">
          <h3>دار الوعي</h3>
          <p>رقم الهاتف: 00213560941010</p>
          <p>الفاكس: 023870495</p>
          <p>
            العنوان: حي البرتقال شارع علي بومنجل رقم 14 قسم مجموعة 122 رويبة
            الجزائر.
          </p>
          <a
            className="link fb"
            href="https://www.facebook.com/elwaailibrairebmr/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <BsFacebook size={30} />
          </a>
        </div>

        <div className="contact-field">
          <h3>مكتبة الوعي للكتب والقرطاسية</h3>
          <p>رقم الهاتف: 021541554</p>
          <p>العنوان: حي لاكونكورد بئر مراد رايس الجزائر العاصمة</p>
          <a
            className="link fb"
            href="https://www.facebook.com/darelwaai/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <BsFacebook size={35} />
            <a
              className="wtsp"
              href="https://wa.me/213561672854"
              target="_blank"
              rel="noreferrer noopener"
            >
              <BsWhatsapp size={35} />
            </a>
          </a>
        </div>

        <div className="contact-field">
          <h3>قسم خدمة التوصيل</h3>
          <p>رقم الهاتف: 00213561621111</p>
          <p>يمكنكم التواصل معنا على الواتساب على نفس الرقم</p>
          <a
            className="wtsp"
            href="https://wa.me/213561621111"
            target="_blank"
            rel="noreferrer noopener"
          >
            <BsWhatsapp size={35} />
          </a>
        </div>

        <div className="contact-field">
          <h3>رقم هاتف القسم التجاري وقسم التوزيع</h3>
          <p>رقم الهاتف: 00213560941010</p>
          <p>يمكنكم التواصل معنا على الواتساب على نفس الرقم</p>
          <p>الإيمايل: Commercial@darelwai.com</p>
          <a
            className="wtsp"
            href="https://wa.me/213560941010"
            target="_blank"
            rel="noreferrer noopener"
          >
            <BsWhatsapp size={35} />
          </a>
        </div>

        <div className="contact-field">
          <h3>رقم هاتف قسم التحرير (قسم النشر)</h3>
          <p>رقم الهاتف: 00213540988613</p>
          <p>يمكنكم التواصل معنا على الواتساب على نفس الرقم</p>
          <p>الإيمايل: Editions@darelwai.com</p>
          <a
            className="wtsp"
            href="https://wa.me/213540988613"
            target="_blank"
            rel="noreferrer noopener"
          >
            <BsWhatsapp size={35} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import "./navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <section className="navbar">
      <div className="navbar-logo">
        <h1>
          <Link to="/" className="link">
            دار الوعي
          </Link>
        </h1>
      </div>
      <nav>
        <ul className="nav-links">
          <li>
            <Link className="nav-link" to="/services">
              <span>خدماتنا</span>
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/about-us">
              <span>من نحن؟</span>
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/editions">
              <span>إصداراتنا</span>
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/conditions">
              <span>التعامل معنا</span>
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/contact-us">
              <span>إتصل بنا</span>
            </Link>
          </li>
        </ul>
      </nav>
    </section>
  );
};

export default Navbar;

import "./service.css";
import { BsWhatsapp } from "react-icons/bs";

const Service = ({ name, desc, icon, link }) => {
  return (
    <div className="service">
      {icon}
      <h1>{name}</h1>
      <p>{desc}</p>
      <a href={link} target="_blank" rel="noreferrer noopener">
        <BsWhatsapp size={35} />
      </a>
    </div>
  );
};

export default Service;

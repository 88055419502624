import "./addbook.css";
import { useState } from "react";
import { db, storage } from "../firebaseconfig";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Link } from "react-router-dom";

const AddBook = () => {
  const [book, setBook] = useState({
    title: "",
    author: "",
    bookSummary: "",
    price: "",
    subject: "",
    img: "",
  });

  const [image, setImage] = useState(null);
  const [adding, setAdding] = useState(false);

  const handleFileUpload = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const addBook = (e) => {
    e.preventDefault();
    setAdding(true);
    const uploadImage = storage.ref(`images/${image.name}`).put(image);
    uploadImage.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref("images")
          .child(image.name)
          .getDownloadURL()
          .then((url) => {
            db.collection("books").add({
              ...book,
              img: url,
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            });
            setAdding(false);
            alert("Book Added");
          });
      }
    );
  };

  return (
    <div className="addbook">
      <div className="addbook-container">
        <h1>إضافة كتاب</h1>
        <form action="submitt" onSubmit={addBook} className="addbook-form">
          <input
            type="text"
            className="addbook-inp"
            value={book.title}
            onChange={(e) => {
              setBook({ ...book, title: e.target.value });
            }}
            placeholder="عنوان الكتاب"
          />
          <input
            type="text"
            className="addbook-inp"
            value={book.author}
            onChange={(e) => {
              setBook({ ...book, author: e.target.value });
            }}
            placeholder="المؤلف"
          />
          <textarea
            rows="6"
            type="text"
            className="addbook-inp"
            value={book.bookSummary}
            onChange={(e) => {
              setBook({ ...book, bookSummary: e.target.value });
            }}
            placeholder="ملخص الكتاب"
          />
          <input
            type="number"
            className="addbook-inp"
            value={book.price}
            onChange={(e) => {
              setBook({ ...book, price: parseInt(e.target.value) });
            }}
            placeholder="السعر"
          />
          <select
            name="pets"
            className="addbook-inp"
            id="pet-select"
            onChange={(e) => {
              setBook({ ...book, subject: e.target.value });
            }}
          >
            <option value="">--موضوع الكتاب(أخرى)--</option>
            <option value="soon">قريبا</option>
            <option value="ideology">كتب فكرية</option>
            <option value="quraan">كتب علوم القرآن والتفسير</option>
            <option value="fikh">كتب الفقه وأصوله</option>
            <option value="akida">كتب العقيدة</option>
            <option value="sira">كتب السيرة</option>
            <option value="hadith">كتب علوم الحديث</option>
            <option value="history">كتب تاريخية</option>
            <option value="tarbya">كتب التربية</option>
            <option value="sociology">كتب الاجتماع</option>
            <option value="philosophy">كتب الفلسفة</option>
            <option value="law">كتب القانون</option>
            <option value="politics">كتب السياسة</option>
            <option value="nahw">كتب النحو والصرف</option>
            <option value="balagha">كتب البلاغة</option>
            <option value="lisanyat">كتب اللسانيات</option>
            <option value="literature">كتب الأدب</option>
            <option value="stories">الرواية والقصة</option>
            <option value="poetry">كتب الشعر</option>
          </select>
          <input
            type="file"
            className="addbook-inp"
            onChange={handleFileUpload}
          />
          <button disabled={adding} type="submit" className="addbook-btn">
            إضافة
          </button>
        </form>
        <button className="addbook-btn-control">
          <Link className="link" to="/control">
            لوحة التحكم
          </Link>
        </button>
      </div>
    </div>
  );
};

export default AddBook;

import React from "react";
import "./services.css";

const Services = () => {
  return (
    <div className="contact">
      <p className="font-services">Service AUDIOTEX</p>
      <p className="font-services">SMS vers Numéro Court Surtaxé</p>
      <div className="services-container">
        <img src="./static/faq.png" alt="faq" />
        <img src="./static/agree.png" alt="aggrement" />
        <img src="./static/prices.png" alt="prices" />
        <img src="./static/contact.png" alt="contact" />
      </div>
    </div>
  );
};

export default Services;

import { Route, Redirect } from 'react-router-dom'
import { useAuth } from './contexts/authContext'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { currentUser, loading } = useAuth();

    return (
        <Route 
            {...rest}
            render={ props => {
                return loading ? <><h1 className='private-loading'>جاري التحميل...</h1></> : (currentUser ? <Component {...props} /> : <Redirect to='/login' />)
        }}/>
    )
}

export default PrivateRoute

import "./login.css";
import { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../contexts/authContext";

const Login = () => {
  const { login } = useAuth();

  const emailRef = useRef();
  const passwordRef = useRef();

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      login(emailRef.current.value, passwordRef.current.value);
      history.push("/control");
    } catch (error) {
      return console.log(error);
    }
    setLoading(false);
  };

  return (
    <div className="login-page">
      <form onSubmit={handleSubmit} className="login-form">
        <input type="email" ref={emailRef} placeholder="Email" />
        <input type="password" ref={passwordRef} placeholder="Password" />
        <button type="submit" disabled={loading}>
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;

import "./show.css";
import Products from "./Products";

const Show = ({ books }) => {
  return (
    <div className="show" id="pub">
      <Products
        type="soon"
        books={books.filter((book) => book.subject === "soon").slice(0, 5)}
      />
      <Products
        type="ideology"
        books={books.filter((book) => book.subject === "ideology").slice(0, 5)}
      />
      <Products
        type="quraan"
        books={books.filter((book) => book.subject === "quraan").slice(0, 5)}
      />
      <Products
        type="fikh"
        books={books.filter((book) => book.subject === "fikh").slice(0, 5)}
      />
      <Products
        type="nahw"
        books={books.filter((book) => book.subject === "nahw").slice(0, 5)}
      />
      <Products
        type="history"
        books={books.filter((book) => book.subject === "history").slice(0, 5)}
      />
    </div>
  );
};

export default Show;

import './aboutus.css'

const AboutUs = () => {
    return (
        <div className="about-us">
            <div className="about-us-container">
                <h1>من نحن؟</h1>

                <p>دار الوعي للنشر و التوزيع مؤسسة تُعنى بالكتاب و نشره. رسالتها أن تَنشُر الوعي بالكتاب و حول الكتاب.</p>
                <p>دار الوعي للنشر و التوزيع مؤسسة تُعنى بالكتاب و نشره. رسالتها أن تَنشُر الوعي بالكتاب و حول الكتاب ، و أن تخدُم الفكر المعتدل الذي يرفع وعي المجتمع و يزيد من قدرته على الارتقاء نحو النهضة الحضارية المنشودة.</p>
                <p>أمّا تأسيسها فكان في عام 2006 ، بجهد من صاحبها السيد محمد مولودي. و منذ ذلك الحين ، أصدرت الدار العديد من الكتب في شتى المواضيع ، غير أنها عُرفت أكثر بإصداراتها في مجال الفقه الإسلامي ، منها على سبيل المثال لا الحصر كتاب : "المبسط في الفقه المالكي" ، إضافة إلى نشرها العديد من الدراسات اللغوية و المراجع التاريخية المتميزة مثل كتاب : "تحفة الزائر في مآثر الأمير عبد القادر" .</p>
                <p>كما اشتهرت الدار أيضا بإعادة طبعها لنتاج المفكر الجزائري مالك بن نبي بعد الاتفاق مع دار الفكر السورية التي تربطها بها علاقات متميزة و تعد الموزع الرئيسي لإصداراتها في الجزائر.</p>
                <p>و لا تزال دار الوعي تُواصل مسيرتها في إثراء المكتبة العربية ، منتهجة سبيل التطوير و الإبداع و مسايرة العصر ، يقينا منها أنّ مستقبل الكتاب في العالم العربي سيكون أفضل من راهنه إن شاء اللّه.</p>
            </div>
        </div>
    )
}

export default AboutUs

import "./conditions.css";
import { Link } from "react-router-dom";

const Conditions = () => {
  return (
    <div className="conditions">
      <div className="conditions-card">
        <h3>شروط النشر</h3>
        <p>نشر المؤلفات في شتى الموضوعات</p>
        <p>أن يكون الكتاب باللغة العربية أو أي لغة أجنبية أخرى</p>
        <p>ألا يكون الكتاب قد نشر من قبل، إلا في حالات خاصة</p>
        <p>
          أن يكون الكتاب جاهزا للطبع، وفي حالة استلام الدار للكتاب غير جاهز فإن
          المؤلف يتحمل كامل تكاليف التصحيح والإخراج واعداد الغلاف
        </p>
        <p>حقوق المؤلف تقدر ب 10٪ من سعر بيع الكتاب بالجملة</p>
        <p>أن لا يكون محتوى الكتاب به ما يسئ أو يمس بثوابت الأمة وأعلامها</p>
        <p>
          يتحمل المؤلف كامل المسؤولية القانونية عن محتوى كتابه، ولا يعبر هذا
          الكتاب عن رأي الدار.
        </p>
        <Link className="link cond-link" to="/contact-us">
          للتواصل معنا إضغط هنا
        </Link>
      </div>
      <div className="conditions-card">
        <h3>شروط التوزيع</h3>
        <p>أن لا يكون محتوى الكتاب يتعارض مع شروط النشر</p>
        <p>تستلم الدار الكتاب مطبوعا جاهزا للبيع في صيغته الورقية</p>
        <p>يوزع الكتاب داخل وخارج الوطن</p>
        <p>
          تتفق الدار مع صاحب الكتاب على سعر شراء محدد، في حين يحدد سعر البيع من
          طرف دار النشر دون تدخل أي طرف آخر بذلك
        </p>
        <p>
          يأخذ صاحب الكتاب المراد توزيعه أمواله على الكميات المباعة في نهاية كل
          سنة تجارية
        </p>
        <Link className="link cond-link" to="/contact-us">
          للتواصل معنا إضغط هنا
        </Link>
      </div>
    </div>
  );
};

export default Conditions;

import './card.css'
import { Link } from 'react-router-dom'

const Card = ({book}) => {
    return (
        <div className="card">
            <div className="card-img">
                <Link to={`/book/${book.id}`}><img className="card-book-cover" src={book.img} alt="Book Cover"/></Link> 
            </div>
        </div>
    )
}

export default Card
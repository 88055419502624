import './bookslist.css'
import { useEffect, useState } from 'react';
import { db } from '../firebaseconfig';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';

const BooksList = () => {
    const history = useHistory();
    const [books, setBooks] = useState([]);

    useEffect(() => {
        const getBooks = () => {
            db.collection('books').orderBy('timestamp', 'desc').onSnapshot(function (querySnapshot) {
                setBooks(querySnapshot.docs.map((book) => ({
                    id: book.id,
                    ...book.data()
                })))
            })
        }
    
        getBooks();
    }, [])

    
    const deleteBook = (id) =>{ 
        db.collection('books').doc(id).delete();
    }

    const { logout } = useAuth();

    const handleLogout = async () => { 
        try {
            await logout();
            history.push('/login')
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="bookslist">
            <div className="bookslist-container">
                <button className='bookslist-btn-add'><Link className='link' to='/addbook'>إضافة كتاب</Link></button>
                <button className='bookslist-btn-logout' onClick={handleLogout}>تسجيل الخروج</button>
                <table>
                    <tr>
                        <th>عنوان الكتاب</th>
                        <th>الكاتب</th>
                        <th>سعر الكتاب</th>
                        <th>تعديل</th>
                    </tr>
                    {
                        books.map((book) => {
                            return (<tr key={book.id}>
                                <td>{book.title}</td>
                                <td>{book.author}</td>
                                <td>{book.price}DA</td>
                                <td>
                                    <button className='bookslist-btn modify'><Link className='link' to={`/modify/${book.id}`} >تعديل</Link></button>
                                    <button className='bookslist-btn delete' onClick={() => deleteBook(book.id)}>حذف</button>
                                </td>
                            </tr>)
                        })
                    }
                </table>
            </div>
        </div>
    )
}

export default BooksList;
import "./search.css";
import { BsSearch } from "react-icons/bs";
import { useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../firebaseconfig";

const Search = ({ books }) => {
  const [searched, setSearched] = useState(false);
  const [search, setSearch] = useState("");
  const [result, setResult] = useState([]);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearched(true);
    db.collection("books")
      .where("title", "==", search)
      .onSnapshot(function (querySnapshot) {
        setResult(
          querySnapshot.docs.map((book) => ({
            id: book.id,
            ...book.data(),
          }))
        );
      });
  };
  return (
    <div className="search">
      <form action="/" method="post" onSubmit={(e) => handleSearch(e)}>
        <input
          name="search"
          type="text"
          placeholder="إبحث عن كتابك..."
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <button type="submit">
          <BsSearch size={26} />
        </button>
      </form>
      {searched ? (
        <div className="search-results">
          {result.length === 0 ? (
            <h2>لا توجد نتائج... حاول مرة أخرى...</h2>
          ) : (
            <>
              <h2>نتائج البحث</h2>
              {result.map((book) => (
                <Link to={"/"} className="carousel-img-link" key={book.id}>
                  <img src={book.img} alt="Cover" />
                </Link>
              ))}
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Search;

import "./hero.css";
import Card from "./Card";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

const Hero = ({ books }) => {
  const slideRight = () => {
    var slider = document.getElementById("cards-container");
    slider.scrollLeft += 400;
  };

  const slideLeft = () => {
    var slider = document.getElementById("cards-container");
    slider.scrollLeft -= 400;
  };

  return (
    <div className="hero">
      <MdChevronRight
        size={50}
        className="slider-icon right"
        onClick={slideRight}
      />
      <div className="cards-container" id="cards-container">
        {books.map((book) => (
          <Card key={book.id} book={book} />
        ))}
      </div>
      <MdChevronLeft
        size={50}
        className="slider-icon left"
        onClick={slideLeft}
      />
    </div>
  );
};

export default Hero;

import "./bookPreview.css";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { db } from "../firebaseconfig";

const BookPreview = () => {
  const { id } = useParams();

  const [book, setBook] = useState({
    title: "",
    author: "",
    bookSummary: "",
    price: "",
    subject: "",
  });

  useEffect(() => {
    const getBook = () => {
      db.collection("books")
        .doc(id)
        .get()
        .then((snapshot) => setBook(snapshot.data()));
    };
    getBook();
  }, [id]);

  return (
    <div className="book-preview">
      <div className="book-preview-container">
        <div className="book-preview-right">
          <img src={book.img} alt="book" />
        </div>
        <div className="book-preview-left">
          <div className="book-preview-left-info">
            <h1>معلومات حول الكتاب</h1>
            <h3>العنوان: {book.title}</h3>
            <h3>المؤلف: {book.author}</h3>
            <h3>السعر: {book.price}دج</h3>
            <h3>ملخص الكتاب: {book.bookSummary}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookPreview;

import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Main from "./pages/Main";
import Footer from "./components/Footer";
import AddBook from "./pages/AddBook";
import BooksList from "./pages/BooksList";
import EditBook from "./pages/EditBook";
import BookPreview from "./pages/BookPreview";
import Login from "./control/Login";
import { AuthProvider } from "./contexts/authContext";
import PrivateRoute from "./PrivateRoute";
import NotFound from "./pages/NotFound";
import AboutUs from "./pages/AboutUs";
import Books from "./pages/Books";
import Contact from "./pages/Contact";
import Conditions from "./pages/Conditions";
import Editions from "./pages/Editions";
import Services from "./pages/Services";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/login" exact component={Login} />
          <Route path="/book/:id" exact component={BookPreview} />
          <Route path="/p/:category" exact component={Books} />
          <Route path="/services" exact component={Services} />
          <Route path="/about-us" exact component={AboutUs} />
          <Route path="/contact-us" exact component={Contact} />
          <Route path="/conditions" exact component={Conditions} />
          <Route path="/editions" exact component={Editions} />
          <PrivateRoute path="/addbook" exact component={AddBook} />
          <PrivateRoute path="/control" exact component={BooksList} />
          <PrivateRoute path="/modify/:id" exact component={EditBook} />

          <Route component={NotFound} />
        </Switch>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;

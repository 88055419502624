import React, { useEffect, useState } from "react";
import "./products.css";
import { Link } from "react-router-dom";
import identifySubject from "../identifySubject";

const Products = ({ type, books }) => {
  const [subject, setSubject] = useState("");

  useEffect(() => {
    setSubject(identifySubject(type));
  }, [type]);
  if (type === "soon") {
    return (
      <div className="products">
        <h2>{subject}</h2>
        <div className="products-container">
          {books.map((book) => (
            <Link to={"/"} className="carousel-img-link" key={book.id}>
              <img src={book.img} alt="Cover" />
            </Link>
          ))}
        </div>
        <Link to={`/p/${type}`} className="link products-more">
          المزيد
        </Link>
      </div>
    );
  }
  return (
    <div className="products">
      <h2>{subject}</h2>
      <div className="products-container">
        {books.map((book) => (
          <Link
            to={`book/${book.id}`}
            className="carousel-img-link"
            key={book.id}
          >
            <img src={book.img} alt="Cover" />
          </Link>
        ))}
      </div>
      <Link to={`/p/${type}`} className="link products-more">
        المزيد
      </Link>
    </div>
  );
};

export default Products;

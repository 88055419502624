import './books.css'
import { useParams, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { db } from '../firebaseconfig';
import identifySubject from '../identifySubject';


const Books = () => {

    const { category } = useParams();

    const [books, setBooks] = useState([]);
    const [subject, setSubject] = useState('')

    

    useEffect(() => {
        console.log(category);
        const getBooks = () => {
            db.collection('books').orderBy('timestamp', 'desc').where('subject', '==', category).onSnapshot(function (querySnapshot) {
                setBooks(querySnapshot.docs.map((book) => ({
                    id: book.id,
                    ...book.data()
                })))
            })
        }
        getBooks();
        setSubject(identifySubject(category))
    }, [category])


    return (
        <div className="books-container"> 
            <h2>{subject}</h2>
            {books.map(book => <Link to={`/book/${book.id}`} className="books-container-img-link" key={book.id}><img src={book.img} alt="Cover" /></Link>)}
        </div>
    )
}

export default Books

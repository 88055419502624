const identifySubject = (type) => {
  switch (type) {
    //   Islamic
    case "soon":
      return "يصدر قريبا";
    case "economics":
      return "إقتصاد";
    case "ideology":
      return "فكر";
    case "quraan":
      return "علوم القرآن والتفسير";
    case "fikh":
      return "الفقه وأصوله";
    case "akida":
      return "العقيدة";
    case "sira":
      return "السيرة";
    case "hadith":
      return "علوم الحديث";
    case "history":
      return "التاريخ";
    //   Sociology
    case "tarbya":
      return "التربية";
    case "sociology":
      return "الاجتماع";
    case "philosophy":
      return "الفلسفة";
    case "law":
      return "القانون";
    case "politics":
      return "السياسة";
    // Lang
    case "nahw":
      return "النحو والصرف";
    case "balagha":
      return "البلاغة";
    case "lisanyat":
      return "لسانيات";
    case "literature":
      return "الأدب";
    case "stories":
      return "الرواية والقصة";
    case "poetry":
      return "الشعر";
    default:
      return "كتب أخرى";
  }
};

export default identifySubject;
